<template>
  <div v-if="isWorking === false">
    <div class="bg-light py-4 header-box" v-b-visible="visibleHandler">
      <b-container class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bold mb-0">
          {{ order.customer.companyProfile.companyName }}
        </h3>

        <shopping-cart-component
          :cotermDate="order.customer.cotermDate"
          :index="0"
          :minimumQuantities="minimumQuantities"
        ></shopping-cart-component>
      </b-container>
    </div>


    <div class="bg-light pb-4 header-box" v-b-visible="visibleHandler">
      <b-container class="d-flex align-items-center">
        <div class="d-flex flex-column mr-4">
          <small class="text-muted">
            {{ $t("creationTime") }}
          </small>
          <div>
            <fa-icon :icon="['fa-regular', 'fa-clock']" class="mr-1"></fa-icon>
            {{ order.creationDate | date }}
          </div>
        </div>

        <div class="d-flex flex-column mr-4">
          <small class="text-muted">
            {{ $t("anniversaryDate") }}
            <fa-icon
              :icon="['fa-regular', 'fa-circle-info']" 
              class="ml-1 text-muted"
              v-b-tooltip.hover.top="this.$t('timeZoneNoticeDate')"
            ></fa-icon>
          </small>
          <div class="d-flex align-items-center">
            <fa-icon :icon="['fa-regular', 'fa-calendar-days']" class="mr-1"></fa-icon>
            {{ order.customer.cotermDate | date }}
          </div>
        </div>

        <div class="d-flex flex-column mr-4" v-if="order.customer && order.customer?.benefits.length > 0 && order.customer?.benefits.filter(d => d.type === 'THREE_YEAR_COMMIT') && order.customer.benefits?.filter(d => d.type === 'THREE_YEAR_COMMIT')[0].commitment?.startDate">
          <small class="text-muted">{{$t("threeYCOption")}}</small>
          <div>
            {{ returnDate(order.customer.benefits?.filter(d => d.type === "THREE_YEAR_COMMIT")[0].commitment.startDate)  }} - {{ returnDate(order.customer.benefits?.filter(d => d.type === "THREE_YEAR_COMMIT")[0].commitment.endDate) || "-" }}
          </div>
        </div>

        <div class="d-flex flex-column mr-4">
          <small class="text-muted">{{$t("marketSegment")}}</small>
          <div>
            {{ order.customer.companyProfile.marketSegment | marketSegment }}
          </div>
        </div>
      </b-container>
    </div>

    <div id="fixed-header-box" class="bg-light shadow-sm py-4" :class="fixedHeaderBoxVisibility">
      <b-container class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <div class="d-flex flex-column mr-4">
            <small class="text-muted">{{ $t("newOrderFor") }} </small>
            <div class="font-weight-bold">
              {{ order.customer.companyProfile.companyName }}
            </div>
          </div>

          <div class="d-flex flex-column mr-4">
            <small class="text-muted">
              {{ $t("creationTime") }}
            </small>
            <div>
              <fa-icon :icon="['fa-regular', 'fa-clock']" class="mr-1"></fa-icon>{{ order.creationDate | date }}
            </div>
          </div>

          <div class="d-flex flex-column mr-4">
            <small class="text-muted">
              {{ $t("anniversaryDate") }}
              <fa-icon
                :icon="['fa-regular', 'fa-circle-info']" 
                class="ml-1 text-muted"
                v-b-tooltip.hover.top="this.$t('timeZoneNoticeDate')"
              ></fa-icon>
            </small>
            <div><fa-icon :icon="['fa-regular', 'fa-calendar-days']" class="mr-1"></fa-icon>{{ order.customer.cotermDate | date }}</div>
          </div>

          <div class="d-flex flex-column mr-4" v-if="order.customer && order.customer?.benefits.length > 0 && order.customer?.benefits.filter(d => d.type === 'THREE_YEAR_COMMIT') && order.customer.benefits?.filter(d => d.type === 'THREE_YEAR_COMMIT')[0].commitment?.startDate">
            <small class="text-muted">{{$t("threeYCOption")}}</small>
            <div>
              {{ returnDate(order.customer.benefits?.filter(d => d.type === "THREE_YEAR_COMMIT")[0].commitment.startDate)  }} - {{ returnDate(order.customer.benefits?.filter(d => d.type === "THREE_YEAR_COMMIT")[0].commitment.endDate) || "-" }}
            </div>
          </div>

          <div class="d-flex flex-column mr-4">
            <small class="text-muted">{{$t("marketSegment")}}</small>
            <div>
              {{ order.customer.companyProfile.marketSegment | marketSegment }}
            </div>
          </div>
        </div>

        <shopping-cart-component
          :index="1"
          :minimumQuantities="minimumQuantities"
        >
        </shopping-cart-component>
      </b-container>
    </div>
    
    <b-container class="pt-4" >
      <OpenAcquisitionsComponent :customerId="order.customer.customerId"/>
    </b-container>

    <b-container class="pt-4 d-flex flex-row">

      <div class="mr-4" style="min-width: 222px">
        <b-card body-class="d-flex justify-content-between" style="position: sticky; top: 100px;">
          <b-nav vertical pills>
            <b-nav-item
              v-on:click="changeFilters(['license', 'teams'])"
              :active="filtersContains(['license', 'teams'])"
            >
              <span v-html="$t('teamsLicenses')"></span>
            </b-nav-item>
            <b-nav-item
              v-on:click="changeFilters(['license', 'teams', 'pro'])"
              :active="filtersContains(['license', 'teams', 'pro'])"
              v-if="customerIsInMarketSegment(['COM', 'GOV'])"
            >
              <span v-html="$t('teamsLicensesProEditions')"></span>
            </b-nav-item>
            <b-nav-item
              v-on:click="changeFilters(['license', 'enterprise'])"
              :active="filtersContains(['license', 'enterprise'])"
            >
              <span v-html="$t('enterpriseLicenses')"></span>
            </b-nav-item>
            <b-nav-item
              v-if="showEdition3"
              v-on:click="changeFilters(['edition3'])"
              :active="filtersContains(['edition3'])"
            >
              <span v-html="$t('edition3')"></span>
            </b-nav-item>
            <b-nav-item
              v-on:click="changeFilters(['license', 'enterprise', 'pro'])"
              :active="filtersContains(['license', 'enterprise', 'pro'])"
              v-if="customerIsInMarketSegment(['COM', 'GOV'])"
            >
              <span v-html="$t('enterpriseLicensesProEditions')"></span>
            </b-nav-item>
            <b-nav-item
              v-on:click="changeFilters(['consumable', 'transaction'])"
              :active="filtersContains(['consumable', 'transaction'])"
            >
              {{ $t("signTransactions") }}
            </b-nav-item>
            <b-nav-item
              v-on:click="changeFilters(['consumable', 'teams'])"
              :active="filtersContains(['consumable', 'teams'])"
              v-if="customerIsInMarketSegment(['COM', 'EDU'])"
            >
              {{ $t("creditPacksTeams") }}
            </b-nav-item>
            <b-nav-item
              v-on:click="changeFilters(['consumable', 'enterprise'])"
              :active="filtersContains(['consumable', 'enterprise'])"
            >
              {{ $t("creditPacksEnterprise") }}
            </b-nav-item>
            <b-nav-item
              v-on:click="changeFilters(['frl', 'license', 'enterprise'])"
              :active="filtersContains(['frl', 'license', 'enterprise'])"
              v-if="customerIsInMarketSegment(['GOV'])"
            >
              FRL
            </b-nav-item>
            <b-nav-item
              v-on:click="changeFilters(['k12', 'license', 'enterprise'])"
              :active="filtersContains(['k12', 'license', 'enterprise'])"
              v-if="customerIsInMarketSegment(['K_12'])"
            >
              K12
            </b-nav-item>
          </b-nav>
        </b-card>
      </div>

      <div>
        <b-card body-class="d-flex justify-content-between" class="mb-4">
          <b-input-group class="w-25">
            <template #prepend>
              <b-input-group-text class="bg-white pr-0"
                ><fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon
              ></b-input-group-text>
            </template>
            <b-input :placeholder="$t('browse')" v-model="search" class="border-left-0" debounce="600"></b-input>
          </b-input-group>

          <b-button-group class="flex-shrink-0">
            <b-button variant="secondary" v-on:click="gridView = 'single-col'" :disabled="gridView === 'single-col'">
              <fa-icon :icon="['fa-regular', 'fa-table-list']" class="mr-2"></fa-icon>{{ $t("list") }}
            </b-button>
            <b-button variant="secondary" v-on:click="gridView = ''" :disabled="gridView === ''">
              <fa-icon :icon="['fa-regular', 'fa-table-cells-large']" class="mr-2"></fa-icon>{{ $t("grid") }}
            </b-button>
          </b-button-group>
        </b-card>

        <grid-component
          :products="products"
          :cotermDate="order.customer.cotermDate"
          :creationDate="order.customer.creationDate"
          :gridView="gridView"
          :filters="filters"
          :search="search"
          :subscribedOfferIds="subscribedOfferIds"
          :blockedProducts="blockedProducts"
          :minimumQuantities="minimumQuantities"
        ></grid-component>
      </div>
    </b-container>
  </div>
  <div class="h-100 d-flex align-items-center justify-content-center py-4" v-else>
    <b-spinner label="Spinning" variant="secondary"></b-spinner>
  </div>
</template>

<script>
import moment from "moment-timezone";
import ShoppingCartComponent from "../components/ShoppingCartComponent.vue";
import GridComponent from "../components/GridComponent.vue";
import OpenAcquisitionsComponent from "../components/OpenAcquisitionsComponent.vue";
import { functions, firestore } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import { doc, getDoc, updateDoc, collection, query, getDocs, where } from "firebase/firestore";
import { getPrices } from "../plugins/customModules/getPrices.js";
import { getProductsFromServer } from "../plugins/customModules/getProducts.js";

export default {
  components: { ShoppingCartComponent, GridComponent, OpenAcquisitionsComponent },
  name: "CreateOrderSelectProductsView",
  data() {
    return {
      timeNow: null,
      startTime: null, 
      search: "",
      products: [],
      isWorking: true,
      order: {},
      gridView: "single-col",
      filters: ["license", "teams"],
      fixedHeaderBoxVisibility: "hide",
      subscribedOfferIds: [],
      hiddenProducts: [],
      hiddenProductsGeneral: [],
      edition3: [],
      renewalOrderFromCurrentYear: false,
      customerHasNoOrders: false,
      hiddenRenewalOfferProducts: [],
      blockedProducts: [],
      marketSegments: null,
      minimumQuantities: {},
      showEdition3: false,
      expressProductCodes: ["001-0346", "003-0346"]
    };
  },
  async mounted() {
    this.order = await this.fetchOrder(this.$route.params.orderId);

    const [
      blockedProducts,
      hiddenRenewalOfferProducts,
      hiddenProducts,
      edition3,
      acrobatClassic,
      hiddenProductsGeneral,
      hiddenForEnterprise,
      minimumQuantities,
      marketSegments,
      customerSubscriptions,
      orderHistory,
      introPromos,
      customer,
    ] = await this.fetchAdditionalData();

    this.blockedProducts = blockedProducts;
    this.hiddenRenewalOfferProducts = hiddenRenewalOfferProducts;
    this.hiddenProducts = hiddenProducts;
    this.edition3 = edition3;
    this.acrobatClassic = acrobatClassic;
    this.hiddenProductsGeneral = hiddenProductsGeneral;
    this.hiddenForEnterprise = hiddenForEnterprise;
    this.minimumQuantities = minimumQuantities;
    this.marketSegments = marketSegments;
    this.introPromos = introPromos.promos;
    this.introPromosExpress = introPromos.expressPromo;
    this.customer = customer;
    this.customerOrderHistory = orderHistory

    this.customerSubscriptions = customerSubscriptions

    this.subscribedOfferIds = customerSubscriptions
      .filter((el) => el.status === "1000" && el.offerId)
      .map((el) => this.removeVolumeLevelFromOfferId(el.offerId));

    this.allSubscriptionOfferIds = customerSubscriptions
      .map((el) => this.removeVolumeLevelFromOfferId(el.offerId));

    this.renewalOrderFromCurrentYear = orderHistory.some(
      (order) =>
        order.orderType === "RENEWAL" &&
        moment(order.creationDate).isBetween(
          moment().tz("America/Los_Angeles").startOf("year"),
          moment().tz("America/Los_Angeles").endOf("year")
        )
    );

    this.customerHasNoOrders = orderHistory.every((order) => order.orderType === "TRANSFER");

    await this.getProducts();

    this.startTime = moment.tz("America/Los_Angeles")
    setInterval(() => {
      this.updateTimeNow();
      }, 1000)
  },
  methods: {
    async updateTimeNow() {
      this.timeNow = moment.tz("America/Los_Angeles")
      const sameDay = moment(this.timeNow).isSame(this.startTime, 'day')
      if (!sameDay) {
        console.info("DayChange")
        this.startTime = moment.tz("America/Los_Angeles");
        this.isWorking = true
        await this.getProducts();
        await this.updatePricesInOrder()
      }
    },
    async updatePricesInOrder() {

      if (this.order == {}) return;
      try {
        const orderId = this.$route.params.orderId
        const orderProductsRef = query(collection(firestore, `orders/${orderId}/products`));
        const productsOfOrder = await getDocs(orderProductsRef);

        if (!productsOfOrder.empty) {
          productsOfOrder.forEach(async document =>{
            const dataOfEl = document.data()
            const found = this.products.find(element=> element.productName == dataOfEl.productName);
            const productRef = doc(firestore, `orders/${orderId}/products`, document.id);
            await updateDoc(productRef, found)
          })
        }
      } catch (error) {
        console.error(error);
        this.$store.dispatch("alertError", {
          message: "Es ist ein Fehler beim aktualisieren der Preise aufgetreten. Bitte versuchen Sie es erneut.",
          show: true,
          noFooter: false,
        });

      }
    },
    async fetchOrder(orderId) {
      try {
        const docRef = doc(firestore, "orders", orderId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          return docSnapshot.data();
        } else {
          this.$store.dispatch("alertError", {
            message: "Der Auftrag konnte nicht abgerufen werden.",
            show: true,
            noFooter: false,
          });

          this.$router.replace({ name: "CreateOrderSelectCustomerView" });
        }
      } catch (error) {
        console.error(error);
        this.$store.dispatch("alertError", {
          message: "Der Auftrag konnte nicht abgerufen werden.",
          show: true,
          noFooter: false,
        });

        this.$router.replace({ name: "CreateOrderSelectCustomerView" });
      }
    },
    async fetchAdditionalData() {
      const promises = [
        this.fetchBlockedProducts(),
        this.fetchHiddenRenewalOfferProducts(),
        this.fetchHiddenProducts(this.order.customer.companyProfile.marketSegment),
        this.fetchHiddenProducts("edition3"),
        this.fetchHiddenProducts("acrobatClassic"),
        this.fetchHiddenProducts("general"),
        this.fetchHiddenProducts("forEnterprise"),
        this.fetchMinimumQuantities(this.order.customer.companyProfile.marketSegment),
        this.fetchMarketSegmentProductTypes(this.order.customer.companyProfile.marketSegment),
        this.fetchCustomerSubscriptions(this.order.customer.customerId),
        this.fetchOrderHistory(this.order.customer.customerId),
        this.fetchIntroPromoProductTypes(),
        this.fetchCustomer(this.order.customer.customerId),
      ];

      return Promise.all(promises);
    },
    async fetchBlockedProducts() {
      const colRef = doc(firestore, "generalSettings", "blockedProducts");
      const snap = await getDoc(colRef);
      return snap.data().offerIds || [];
    },
    async fetchHiddenRenewalOfferProducts() {
      const colRef = doc(firestore, "generalSettings", "hiddenRenewalOfferProducts");
      const snap = await getDoc(colRef);
      return snap.data().productCodes || [];
    },
    async fetchHiddenProducts(marketSegment) {
      const colRef = doc(firestore, "generalSettings", "hiddenProducts");
      const snap = await getDoc(colRef);
      return snap.data()[marketSegment] || [];
    },
    async fetchMinimumQuantities(marketSegment) {
      const colRef = doc(firestore, "generalSettings", "minimumQuantities");
      const snap = await getDoc(colRef);
      return snap.data()[marketSegment] || {};
    },
    async fetchMarketSegmentProductTypes(marketSegment) {
      const colRef = doc(firestore, "generalSettings", "marketSegments");
      const snap = await getDoc(colRef);
      return snap.data()[marketSegment] || null;
    },
    async fetchIntroPromoProductTypes() {
      const colRef = doc(firestore, "generalSettings", "introPromo");
      const snap = await getDoc(colRef);
      return snap.data() || null;
    },
    async fetchCustomer(customerId) {
      const productsColRef = query(collection(firestore, "customers"), where("customerId", "==", customerId));
      const customers = await getDocs(productsColRef);

      if (customers.empty) {
        return null;
      } else {
        return customers.docs[0].data()
      }

    },
    async fetchCustomerSubscriptions(customerId) {
      const getCustomerSubscriptions = httpsCallable(functions, "getCustomerSubscriptions");
      const result = await getCustomerSubscriptions({
        customerId: customerId,
      });
      return result.data.items || [];
    },
    customerIsInMarketSegment(marketSegments) {
      const isInMarketSegment = marketSegments.includes(this.order.customer.companyProfile.marketSegment);
      let isInMarketSubSegments = false;

      if (this.order.customer.companyProfile.marketSubSegments) {
        isInMarketSubSegments = marketSegments.includes(
          this.order.customer.companyProfile.marketSubSegments.find(
            (marketSubSegment) => marketSubSegment === marketSegments[0]
          )
        );
      }

      return isInMarketSegment || isInMarketSubSegments;
    },
    visibleHandler(isVisible) {
      if (isVisible === false) {
        this.fixedHeaderBoxVisibility = "show";
      } else {
        this.fixedHeaderBoxVisibility = "hide";
      }
    },
    cotermDateInLastSevenDays(cotermDate) {
      const daysBetweenTodayAndCotermDate = moment.tz("America/Los_Angeles").diff(moment.tz(cotermDate, "America/Los_Angeles"), "days");

      return (
        (daysBetweenTodayAndCotermDate <= 7 && !daysBetweenTodayAndCotermDate < 0) || // Renewal is within 7 days (current year)
        (daysBetweenTodayAndCotermDate >= -365 && daysBetweenTodayAndCotermDate <= -358)
      ); // Renewal is within 7 days (next year)
    },
    async fetchOrderHistory(customerId) {
      const getCustomerOrderHistory = httpsCallable(functions, "getCustomerOrderHistory");

      const result = await getCustomerOrderHistory({
        customerId: customerId,
      });

      return result?.data?.items || false;
    },
    customerIsallowedToBuyAcrobatClassic() {
      let creationDate = this.order.customer.creationDate;
      let cotermDate = this.order.customer.cotermDate;

      const years = moment(moment(cotermDate, "YYYY-MM-DD")).diff(moment(creationDate, "YYYY-MM-DD"), 'years',false);
      const daysSinceCreation = moment().diff(moment(creationDate, "YYYY-MM-DD"), 'days', false);
      
      const acrobatClassicOfferIds = ["30006211CA00A12", "30006207CA00A12", "30006345CA00A12", "30006339CA00A12"]
      const found = this.allSubscriptionOfferIds.some(r=> acrobatClassicOfferIds.includes(r))

      if (years == 3 && daysSinceCreation < 16 || found) {
        return true;
      }
      return false;
    },
    customerIsallowedToBuyForEnterprise(itemNumberObject) {
      // 3yc Ausnahme für "for Enterprise" Produkte. Muss bis 01.08.2027 bleiben.
      // EDU & GOV dürfen weiterhin "for Enterprise" kaufen?


      const marketSegment = itemNumberObject.marketSegment
      const comOrEdu = marketSegment == "33" || marketSegment == "32" || marketSegment == "21" || marketSegment == "22" ? true : false;

      const threeycStartDate = this.order.customer.benefits?.filter(d => d.type === "THREE_YEAR_COMMIT")?.[0]?.commitment?.startDate;
      const lastDayToCommit = moment("2024-07-29", "YYYY-MM-DD");      

      if (comOrEdu || threeycStartDate && moment(threeycStartDate, "YYYY-MM-DD").isSameOrBefore(lastDayToCommit)) {
        return true;
      }
      return false;
    },
    customerIsNotAllowedToBuyDdition3() {
      if(this.customer?.showHiddenProducts && this.customer?.showHiddenProducts.includes("edition3")){
        // console.info("Edition 3")
        this.showEdition3 = true;
        return false;
      }
      return true;
    },
    async getProducts() {
      console.info("loading Products")
      const products = await getProductsFromServer(this.$store.state.resellerData.resellerId);

      if (products.empty) {
        this.$store.dispatch("alertError", {
          message: "Es wurden keine Produkte gefunden.",
          show: true,
          noFooter: false,
        });
        this.$router.replace({ name: "CreateOrderSelectCustomerView" });
      }

      const requestProducts = [];
//EXPRESS SPECIAL PROMO

      const customerHasExpressnoPromo = this.introPromosExpress.noPromo.filter(el => this.subscribedOfferIds.indexOf(this.removeVolumeLevelFromOfferId(el)) !== -1).length > 0;
      const customerHasExpressintroPromo = this.introPromosExpress.introPromo.filter(el => this.subscribedOfferIds.indexOf(this.removeVolumeLevelFromOfferId(el)) !== -1).length > 0;
      
      const customerHasExpressfirstYear = this.introPromosExpress.firstYear.filter(e => this.subscribedOfferIds.indexOf(this.removeVolumeLevelFromOfferId(e)) !== -1).length > 0;
      const customerHasExpressnextTwoYears = this.introPromosExpress.nextTwoYears.filter(e => this.subscribedOfferIds.indexOf(this.removeVolumeLevelFromOfferId(e)) !== -1).length > 0;

      const customerIsAllowedToOrderfirstYear = customerHasExpressfirstYear && !customerHasExpressnoPromo && !customerHasExpressintroPromo;
      const customerIsAllowedToOrdernextTwoYears = customerHasExpressnextTwoYears && !customerHasExpressnoPromo && !customerHasExpressintroPromo;
      let CustomerRenewaltExpress = false;

      this.customerOrderHistory && this.customerOrderHistory.forEach(element => {
        element.lineItems.forEach((item) => {
          const isExpress = Object.entries(this.introPromosExpress).find(each=>each[1].includes(item.offerId))?.[0] || false;  
          isExpress && (CustomerRenewaltExpress = isExpress);
        })
      });

      console.log("customerHasExpressnoPromo", this.introPromosExpress.noPromo.filter(el => this.subscribedOfferIds.indexOf(this.removeVolumeLevelFromOfferId(el)) !== -1))
      console.log("customerHasExpressintroPromo", this.introPromosExpress.introPromo.filter(el => this.subscribedOfferIds.indexOf(this.removeVolumeLevelFromOfferId(el)) !== -1))
      console.log("customerHasExpressfirstYear", this.introPromosExpress.firstYear.filter(e => this.subscribedOfferIds.indexOf(this.removeVolumeLevelFromOfferId(e)) !== -1))
      console.log("customerHasExpressnextTwoYears", this.introPromosExpress.nextTwoYears.filter(e => this.subscribedOfferIds.indexOf(this.removeVolumeLevelFromOfferId(e)) !== -1))
      console.log("CustomerRenewaltExpress", CustomerRenewaltExpress)
//EXPRESS SPECIAL PROMO
      const customerHasOrHadIntroPromo = this.introPromos.find(each=>each.introPromoSkus.filter(e => this.allSubscriptionOfferIds.indexOf(e) !== -1));
      const customerCantBuyIntroPromo = this.customerSubscriptions.filter(e => (customerHasOrHadIntroPromo?.introPromoSkus.includes(this.removeVolumeLevelFromOfferId(e.offerId)) && moment(e.creationDate).isAfter(moment(this.order.customer.cotermDate)))) || []
      // console.log("customerCantBuyIntroPromo", moment(customerCantBuyIntroPromo.creationDate).isAfter(moment(this.order.customer.cotermDate)))

      products.forEach((productData) => {

        const itemNumberObject = this.$options.filters.itemNumberObject(productData.itemNumber);

        // Check if the volume level of the product is 1
        const isVolumeLevelOne = itemNumberObject.volumeLevel === 1;

        // Check if the product type is not 3YC
        const isNot3YC = !itemNumberObject.productType.match(/^[0-9]{2}3/g);

        // Checks if coterm date is within last seven days and a renewal from current year exists.
        const isWithinLastSevenDaysAndRenewalFromCurrentYearExists =
          this.cotermDateInLastSevenDays(this.order.customer.cotermDate) && this.renewalOrderFromCurrentYear;

        // Checks if the customer has no order in general.
        const customerHasNoOrders = this.customerHasNoOrders;

        // Checks if the customer's coterm date in the order is an empty string.
        const cotermDateIsEmpty = this.order.customer.cotermDate === "";

        // Checks if the customer's coterm date is in the future and within the current year.
        const cotermDateIsInFutureAndWithinCurrentYear =
          moment(this.order.customer.cotermDate).isAfter(moment()) &&
          moment(this.order.customer.cotermDate).isSame(moment(), "year");

        // Checks if the customer's coterm date is after the current year.
        const cotermDateIsAfterCurrentYear = moment(this.order.customer.cotermDate).isAfter(moment().format("YYYY"));

        if(this.customerIsNotAllowedToBuyDdition3()){
          this.hiddenProducts = [...new Set(this.hiddenProducts), ...new Set(this.edition3)]
        }

        if(this.hiddenProductsGeneral?.length > 0){
          this.hiddenProducts = [...new Set(this.hiddenProducts), ...new Set(this.hiddenProductsGeneral)]
        }

        // Check if the product is not included in the hidden products list.
        const isNotIncludedInHiddenProducts = !this.hiddenProducts.includes(
          `${itemNumberObject.productType}-${itemNumberObject.productCode}`
        );

        // Check if the product is acrobatClassic.
        const isAcrobatClassic = this.acrobatClassic.includes(
          `${itemNumberObject.productType}-${itemNumberObject.productCode}`
        );

        // Check if the product is "for Enterprise".
        const isForEnterprise = this.hiddenForEnterprise.includes(
          `${itemNumberObject.productType}-${itemNumberObject.productCode}`
        );

        // Check if the product is not included in the hidden renewal offer products list
        const isNotIncludedInHiddenRenewalOfferProducts = !this.hiddenRenewalOfferProducts.includes(
          `${itemNumberObject.productType}-${itemNumberObject.productCode}`
        );

        // Check if the product type of the current item number object is included in the market segment product types
        const isIncludedInMarketSegments = this.marketSegments.includes(itemNumberObject.marketSegment);

//EXPRESS SPECIAL PROMO
        const isExpress = Object.entries(this.introPromosExpress).find(each=>each[1].includes(productData.manufacturerItemNumber))?.[0] || false;

        let cotermDate = this.order.customer.cotermDate;

        const daysUntilCotermDate = moment(moment(cotermDate, "YYYY-MM-DD")).diff(moment(), 'days',false);

        let ProduktIsExpressAndCustomerIsAllowedToBuyIt = false;
        const ExpressPromoEnd = new Date("2024-12-13")

        if (isExpress == "noPromo" && customerHasExpressnoPromo || isExpress == "noPromo" && ExpressPromoEnd < new Date() && !customerHasExpressintroPromo && !customerIsAllowedToOrderfirstYear && !customerIsAllowedToOrdernextTwoYears || CustomerRenewaltExpress == "introPromo" && isExpress == "noPromo" || CustomerRenewaltExpress == "noPromo" && isExpress == "noPromo" || daysUntilCotermDate > 365 && isExpress == "noPromo"){
          ProduktIsExpressAndCustomerIsAllowedToBuyIt = true;     
        } else if (isExpress == "introPromo" && customerHasExpressintroPromo && CustomerRenewaltExpress !== "introPromo" && CustomerRenewaltExpress !== "noPromo" && daysUntilCotermDate < 366){
          ProduktIsExpressAndCustomerIsAllowedToBuyIt = true;
        } else if (isExpress == "firstYear" && customerIsAllowedToOrderfirstYear || isExpress == "firstYear" && ExpressPromoEnd > new Date() && CustomerRenewaltExpress !== "introPromo" && CustomerRenewaltExpress !== "noPromo" && CustomerRenewaltExpress !== "nextTwoYears" && daysUntilCotermDate < 366){
          ProduktIsExpressAndCustomerIsAllowedToBuyIt = true;
        } else if (isExpress == "nextTwoYears" && customerIsAllowedToOrdernextTwoYears && daysUntilCotermDate < 366) {
          ProduktIsExpressAndCustomerIsAllowedToBuyIt = true;
        } else if (!isExpress) {
          ProduktIsExpressAndCustomerIsAllowedToBuyIt = true;
        } else {
          ProduktIsExpressAndCustomerIsAllowedToBuyIt = false;
        }
//EXPRESS SPECIAL PROMO

        // const cotermDateIsInFuture = moment(this.order.customer.cotermDate).isAfter(moment())
        // if(this.allSubscriptionOfferIds) console.log(this.allSubscriptionOfferIds)

        // IntroPromo-Produkte (Promocode - 2) dürfen nur bestellt werden wenn der Kunden diese noch nicht renewalt hat oder vor seinem Renewal diese Produkte nicht genuzt hat. der Preis gilt also von Kauf bis zum nächsten renewal.   
        const customerIsAllowedToOrderIntroPromo = true; // muss noch definiert werden Wurde das Product bestellt? 
        // Kunde darf nicht das Produkt mit Promocode 2 bestellen wenn: Er das Produkt vor seinem Renewal Date bestellt hat und das renewal Date nach der bestellung und vor heute ist. 

        const productIsIntroPromoProduct = this.introPromos.find(each=>each.productCodes.includes(`${itemNumberObject.productType}-${itemNumberObject.productCode}`))
        const productIsIntroPromoType = productIsIntroPromoProduct || false
        const isIntroPromoAndCustomerIsAllowedToBuyIt = (productIsIntroPromoType && itemNumberObject.promoCode == 2 && customerIsAllowedToOrderIntroPromo && productIsIntroPromoType?.introPromoEnd.toDate() > new Date() && customerCantBuyIntroPromo.length <= 0 ) || // kunde darf Intropromo kaufen und produkt ist Intropromo
                                                        (productIsIntroPromoType && itemNumberObject.promoCode != 2 && !customerIsAllowedToOrderIntroPromo) || // produkt ist promoproduct 
                                                        productIsIntroPromoType?.introPromoEnd && productIsIntroPromoType?.introPromoEnd.toDate() < new Date() && itemNumberObject.promoCode != 2|| // Date ist nicht abgelaufen
                                                        (!productIsIntroPromoType) // produkt ist nicht Intropromo
                                                        ? true : false;

        if (isVolumeLevelOne && isNot3YC && isIncludedInMarketSegments && isIntroPromoAndCustomerIsAllowedToBuyIt && ((isAcrobatClassic && this.customerIsallowedToBuyAcrobatClassic()) || !isAcrobatClassic) && ((isForEnterprise && this.customerIsallowedToBuyForEnterprise(itemNumberObject)) || !isForEnterprise) && ProduktIsExpressAndCustomerIsAllowedToBuyIt) {
          if (
            isWithinLastSevenDaysAndRenewalFromCurrentYearExists ||
            customerHasNoOrders || 
            cotermDateIsEmpty ||
            cotermDateIsInFutureAndWithinCurrentYear ||
            cotermDateIsAfterCurrentYear
          ) {
            // Is allowed to order promotional products
            if (isNotIncludedInHiddenProducts) {
              // If the product's special price function is not equal to the new price postfix, add the product data to the request products list
              requestProducts.push(productData);
            }
          } else {
            // Is not allowed to order promotional products
            if (isNotIncludedInHiddenRenewalOfferProducts) {
              // If the product's special price function is not equal to the new price postfix, add the product data to the request products list
              requestProducts.push(productData);
            }
          }
        }
      });   
      const productsWithPrices = await getPrices(this.customer.customerId ,{
        benefits: this.order.customer.benefits,
        cotermDate: this.order.customer.cotermDate,
        referenceDate: moment().tz("America/Los_Angeles").add(1, "days").format("YYYY-MM-DD"),
        discounts: this.order.customer.discounts,
        products: requestProducts,
        resellerId: this.order.customer.resellerId,
        marketSegment: this.order.customer.companyProfile.marketSegment,
      });
      this.products = productsWithPrices.data;

      this.isWorking = false;
    },
    changeFilters(values) {
      this.filters = values;
    },
    filtersContains(values) {
      const results = [];
      values.forEach((value) => results.push(this.filters.includes(value)));

      if (results.includes(false) || values.length !== this.filters.length) {
        return false;
      } else {
        return true;
      }
    },
    removeVolumeLevelFromOfferId(offerId) {
      return offerId.replace(/CA\d\d/, "CA00").replace(/CAT\d/, "CAT0");
    },
    returnDate(date) {
      let dateToReturn = new Date(date)
      return dateToReturn.toLocaleDateString('de-DE')
    },
  },
};
</script>

<style scoped>
#fixed-header-box {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

#fixed-header-box.hide {
  transform: translateY(-100%);
}

#fixed-header-box.show {
  transform: translateY(0);
  transition: transform 0.3s ease;
}
</style>
